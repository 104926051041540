<template>
  <v-card>
    <v-card-title>
      <v-col>
        <span class="headline">{{ title + " - " + nameCard }}</span>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="trabajo.linea"
              :items="lineas"
              item-text="nombre"
              item-value="nombre"
              :append-icon="lineas.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Lista de líneas"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Esta línea no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="trabajo.agenteVenta"
              :items="agentes"
              item-text="nombre"
              item-value="nombre"
              :append-icon="agentes.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Agentes de venta"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este agente no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="trabajo.periodo"
              :items="periodos"
              item-text="idP"
              item-value="idP"
              :append-icon="periodos.length == 0 ? 'mdi-reload' : ''"
              @change="getMat(trabajo.periodo)"
              clearable
              hide-details
              label="Lista de periodos"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este periodo no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="trabajo.materia"
              auto-select-first
              :items="materiasByp"
              item-text="nombre"
              item-value="nombre"
              @change="buscaDoc(true)"
              clearable
              hide-details
              label="Lista de materias"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Esta materia no existe</v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selected="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="trabajo.docente"
              auto-select-first
              :items="listaDocentes"
              item-text="nombre"
              item-value="id"
              clearable
              hide-details
              multiple
              label="Lista de docentes"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este docente no existe</v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.correo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selected="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.correo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              v-model="trabajo.notas"
              label="Notas para el encargado"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              dense
              v-model="trabajo.linkDrive"
              label="LINK DRIVE"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="4" md="4">
              <v-text-field v-model="trabajo.valorEmpleado" label="Valor de empleado"></v-text-field>
            </v-col> -->
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              v-model="trabajo.fase"
              label="Fase"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              v-model="trabajo.tutor"
              label="Tutor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" v-if="listaLink">
            <v-container v-bind:style="{ 'text-align': 'center' }">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  v-for="(data, i) in listaLink"
                  v-bind:key="i"
                >
                  <v-btn
                    small
                    color="primary"
                    v-if="listaLink.length != 0"
                    @click="goLink(data)"
                    >Ver archivo {{ i + 1 }}</v-btn
                  >
                  <!-- <v-icon @click="deleteL(data)"> mdi-close</v-icon> -->
                </v-col>
                <v-col>
                  <h3
                    color="primary"
                    v-if="listaLink.length == 0 || listaLink == ''"
                  >
                    No hay archivos cargados
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <input
              type="file"
              hidden
              multiple
              ref="files"
              @change="listFiles"
            />
            <v-select
              dense
              v-model="files"
              :items="files"
              clearable
              readonly
              prepend-icon="mdi-file"
              multiple
              @click="$refs.files.click()"
              @click:prepend="$refs.files.click()"
              label="* Agregar archivos"
              append-icon
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              dense
              v-model="trabajo.cuotasTotales"
              label="Abonos totales"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              dense
              v-model="trabajo.valorAcordado"
              prefix="$"
              label="Valor acordado"
            ></v-text-field>
            {{ trabajo.valorAcordado | currency }}
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-row></v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cancelar</v-btn>
      <v-btn
        :disabled="guardando"
        color="primary"
        text
        @click="trabajo.id == null ? saveTrabajo() : saveEditV()"
        >Guardar</v-btn
      >
    </v-card-actions>
    <template>
      <v-dialog max-width="30%" v-if="infoP" v-model="infoP">
        <v-card>
          <v-card-text>
            <h4 class="alert">{{ mensaje.toString().replace(",", "\n") }}</h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="second" text @click="infoP = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
// import listInfo from "../Tools/getListInfo";
import { host } from "../Tools/variables";
import firebase from "firebase";
import router from "../Tools/rutas";
import axios from "axios";
import data from "../Tools/data";

export default {
  name: "ventaTrabajoView",
  props: [
    "nameCard",
    "info",
    "idCli",
    "title",
    "frecV",
    "listaLink",
    "linkDrive",
  ],
  data() {
    return {
      guardando: false,
      normal: true,
      lineas: [],
      // dataLineas: [],
      agentes: [],
      // dataAgentes: [],
      periodos: [],
      // dataPeriodos: [],
      materiasByp: [],
      medioVenta: [],
      // dataMediosVenta: [],
      listaDocentes: [],
      // dataCursos: [],
      materiasList: [],
      trabajo: {
        id: null,
        docs: [],
        linea: null,
        agenteVenta: null,
        materia: null,
        periodo: null,
        linkDrive: null,
        medioVenta: null,
        docente: [],
        cuotasTotales: 2,
        valorAcordado: null,
        notas: null,
        materiasRepo: null,
        tutor: null,
      },
      files: [],
      files2: [],
      lFiles: [],
      bases64: null,
      menu: false,
      menuT: false,
      dates: [],
      frecuencia: [
        { name: "Mensual", dias: 30 },
        { name: "Quincenal", dias: 15 },
        { name: "Semanal", dias: 7 },
      ],
      frec: null,
      infoP: "",
      mensaje: [],
    };
  },
  async created() {
    // LINEAS
    if (this.$store.state.lineas.length == 0) {
      this.lineas = await data.dataList("/lineas", "lista", "listar", "lineas");
    } else {
      this.lineas = this.$store.state.lineas;
    }
    // AGENTES
    if (this.$store.state.agentes.length == 0) {
      this.agentes = await data.dataList(
        "/agentes",
        "lista",
        "listar",
        "agentes"
      );
    } else {
      this.agentes = this.$store.state.agentes;
    }
    // PERIODOS
    if (this.$store.state.periodos.length == 0) {
      this.periodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
    } else {
      this.periodos = this.$store.state.periodos;
    }
    // MEDIO VENTA
    if (this.$store.state.mediosVenta.length == 0) {
      this.medioVenta = await data.dataList(
        "/mediosVenta",
        "lista",
        "listar",
        "mediosVenta"
      );
    } else {
      this.medioVenta = this.$store.state.mediosVenta;
    }
    if (this.$store.state.materias.length == 0) {
      await data.dataList("/cursos", "lista", "listar", "materias");
    }
    if (this.$store.state.cursos.length == 0) {
      this.materiasList = await data.dataList(
        "/cursosBase",
        "lista",
        "listar",
        "cursos"
      );
    } else {
      this.materiasList = this.$store.state.cursos;
    }
  },

  compwauted: {
    materiasList: function () {
      var info = [];
      for (var m = 0; m < this.$store.state.cursos.length; m++) {
        info.push({
          nombre:
            this.$store.state.cursos[m]["cursoBase"] +
            "-" +
            this.$store.state.cursos[m]["periodo"],
          active: this.$store.state.cursos[m]["recibeTrabajos"],
        });
      }
      return info;
    },
  },

  mounted() {
    if (this.info != null) {
      this.trabajo.periodo = this.info.periodo;
      this.trabajo.id = this.info.id;
      this.trabajo.materia = this.info.materia;
      this.trabajo.linea = this.info.linea;
      this.trabajo.agenteVenta = this.info.agenteVenta;
      this.trabajo.fase = this.info.fase;
      this.trabajo.tutor = this.info.tutor;
      this.trabajo.valorEmpleado = this.info.valorEmpleado;
      this.trabajo.linkDrive = this.info.linkDrive;
      this.trabajo.valorAcordado = this.info.valorAcordado;
      this.trabajo.cuotasTotales = this.info.cuotasTotales;
      this.trabajo.notas = this.info.notas;
      this.frec = this.frecV;
      this.trabajo.ventaId = this.info.ventaId;
      this.getMat(this.trabajo.periodo);
      this.buscaDoc(false);
    } else {
      if (this.linkDrive != null) {
        this.trabajo.linkDrive = this.linkDrive;
      }
    }
  },
  methods: {
    getMat(a) {
      this.materiasByp = [];
      for (var n = 0; n < this.$store.state.materias.length; n++) {
        if (this.$store.state.materias[n]["periodo"] == a) {
          this.materiasByp.push({
            nombre: this.$store.state.materias[n]["cursoBase"],
            codigo: this.$store.state.materias[n]["codigoMadre"],
          });
        }
      }
    },

    saveTrabajo() {
      if (this.trabajo.docente == null || this.trabajo.docente == "") {
        this.$alert("Está venta no tiene asignado un docente");
      } else {
        this.guardando = true;
        // editar
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("estudianteId", this.idCli);
            fd.append("tipo", "agregar_trabajo");
            fd.append("linea", this.trabajo.linea);
            fd.append("agenteVentas", this.trabajo.agenteVenta);
            fd.append("linkDrive", this.trabajo.linkDrive);
            fd.append("periodo", this.trabajo.periodo);
            fd.append("cantidadMaterias", this.trabajo.materia.length);
            fd.append("medioVenta", "");
            fd.append("materia", this.trabajo.materia),
              fd.append("docentes", this.trabajo.docente);
            fd.append(
              "notas",
              this.trabajo.notas == null ? "" : this.trabajo.notas
            );
            fd.append("fase", this.trabajo.fase);
            fd.append(
              "tutor",
              this.trabajo.tutor == null ? "" : this.trabajo.tutor
            );
            fd.append("archivos", this.files2);
            for (var i = 0; i < this.files2.length; i++) {
              let file = this.files2[i];
              fd.append("files[" + i + "]", file);
            }
            fd.append("valorAcordado", this.trabajo.valorAcordado);
            // fd.append("linkDrive", this.trabajo.linkDrive);
            fd.append("valorEmpleado", this.trabajo.valorEmpleado);
            fd.append("cuotasTotales", this.trabajo.cuotasTotales);
            let headers = {
              "Content-Type": "multipart/form-data",
            };
            axios.post(host + "/ventas", fd, headers).then((result) => {
              let data = result.data.text;
              if (data == "OK") {
                this.guardando = false;
                router.push("../trabajosAdmin");
              } else {
                this.guardando = false;
                this.$alert(JSON.stringify(data));
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    buscaDoc(u) {
      this.mensaje = [];
      this.listaDocentes = [];
      if (this.trabajo.periodo != null) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("cursoBase", this.trabajo.materia);
            fd.append("periodo", this.trabajo.periodo);
            axios.post(host + "/docentesPorCurso", fd).then((result) => {
              if (result.data.text == "OK") {
                var data = result.data.lista;
                for (let i = 0; i < data.length; i++) {
                  this.listaDocentes.push({
                    nombre:
                      data[i]["primerNombre"] + " " + data[i]["primerApellido"],
                    id: data[i]["userId"],
                    correo: data[i]["email"],
                  });
                  this.trabajo.docente.push(data[i]["userId"]);
                }
              } else {
                this.$alert(
                  "Error al cargar la información de los docentes para está materia"
                );
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
        const resObject = this.materiasList.find(
          (item) =>
            item.nombre === this.trabajo.materia + "-" + this.trabajo.periodo
        );
        if (resObject) {
          if (resObject.active == false) {
            this.guardando = false;
            this.mensaje.push(
              "No se estan recibiendo ventas para este curso !"
            );
            if (this.mensaje.length != 0 && u == true) {
              this.infoP = true;
            }
          }
        }
      }
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "listar");
          // fd.append("curso", this.trabajo.materia);
          axios.post(host + "/datosTrabajoVenta", fd).then((result) => {
            if (result.data.text != "OK") {
              this.$alert(result.data.text);
            } else {
              var info = result.data.lista;
              const resObject = info.find(
                (item) => item.curso === this.trabajo.materia
              );
              this.mensaje.push(resObject.notas);
              if (this.mensaje.length != 0 && u == true) {
                this.infoP = true;
              }
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    listFiles(event) {
      this.files = [];
      this.files2 = Array.prototype.slice.call(event.target.files);
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i].name);
        this.lFiles.push(this.$refs.files.files[i]);
      }
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async tobase(files) {
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(this.toBase64(file));
      });
      const filePaths = await Promise.all(filePathsPromises);
      this.bases64 = filePaths;
    },

    close() {
      this.$emit("toggle", "close");
    },

    goLink(s) {
      window.open(s, "_blank");
    },

    saveEditV() {
      if (this.trabajo.docente == null || this.trabajo.docente == "") {
        this.$alert("Está venta no tiene asignado un docente");
      } else {
        this.guardando = true;
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "editar_venta_trabajo");
            fd.append("ventaId", this.trabajo.id);
            fd.append("abonos", this.trabajo.cuotasTotales);
            fd.append("agente", this.trabajo.agenteVenta);
            fd.append("linkDrive", this.trabajo.linkDrive);
            fd.append("archivos", this.bases64);
            fd.append("curso", this.trabajo.materia);
            fd.append("fase", this.trabajo.fase);
            fd.append("linea", this.trabajo.linea);
            fd.append("periodo", this.trabajo.periodo);
            fd.append("tutor", this.trabajo.tutor);
            fd.append("docentes", this.trabajo.docente);
            fd.append("valorAcordado", this.trabajo.valorAcordado);
            fd.append("valorEmpleado", this.trabajo.valorEmpleado);
            fd.append(
              "notas",
              this.trabajo.notas == null ? "" : this.trabajo.notas
            );
            fd.append("archivos", this.files2);
            for (var i = 0; i < this.files2.length; i++) {
              let file = this.files2[i];
              // Here we create unique key 'files[i]' in our response dict
              fd.append("files[" + i + "]", file);
            }
            let headers = {
              "Content-Type": "multipart/form-data",
            };
            axios.post(host + "/ventas", fd, headers).then((result) => {
              this.guardando = false;
              let data = result.data.text;
              if (data == "OK") {
                this.$emit("toggle", this.trabajo);
              } else {
                this.$alert("Error: " + data);
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    deleteL() {},
  },
};
</script>

<style scoped>
.alert {
  padding-top: 4%;
  text-align: center;
  font-size: 25px;
  color: rgb(255, 151, 82);
  font-weight: 500;
}
</style>